import React from 'react';

import {NavLink, Route, Routes, useParams} from "react-router-dom";
import {useLocation, useNavigate} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import UserStates from "../UserStates";
import Charger from "../Charger/cm";

import WhiteListManagement from "../WhiteListManagement";
import {Box, Drawer, MenuItem, MenuList, Toolbar} from "@mui/material";
import SearchBar from "../Search";

import DashboardCM from "../Dashboard/cm";
import UserStatesCM from "../UserStates/cm";
import ChargeManagement from "../ChargeManagement/cm";
import PrefabsCm from "../Prefabs/cm/PrefabsCm";
import AllCmChargerLogs from "../ChargerLogs/cm/AllCmChargerLogs";
import ArchiveRT from "../Archive/rt/ArchiveRT";
import ArchiveCM from "../Archive/cm/ArchiveCM";
import SearchBarCM from "../Search/SearchCM";
import AdvancedSearch from "../AdvancedSearch";
import AdvancedSearchCM from "../AdvancedSearch/AdvancedSearchCM";
import AnalyticsCM from "../Analytics/cm";

export default function MainCM(props) {
    const {game, action} = useParams();

    const CommonData = useSelector((state) => state.data.CommonData)
    let deviceId = CommonData.deviceId ? CommonData.deviceId : '';
    let vendorId = CommonData.stateId ? CommonData.stateId : '';

    const dispatch = useDispatch();

    if (deviceId !== '' && deviceId !== CommonData.deviceId) {
        dispatch({
            type: "SET_DEVICE_ID",
            payload: {deviceId},
        });
    } else {
        if (typeof CommonData.deviceId == 'string') {
            deviceId = CommonData.deviceId
        }
    }


    const main_menu = [
        {
            to: '/' + game + '/dashboard/' + deviceId + '?vendorId=' +vendorId,
            path: 'dashboard/:deviceId?',
            title: 'Dashboard',
            element: <DashboardCM/>
        },
    ];
    const state_menu = [
        {
            to: '/' + game + '/state/' + deviceId + '?vendorId=' +vendorId,
            path: 'state/:deviceId?/:tab?/:subtab?',
            title: 'User State',
            element: <UserStatesCM/>,
            // disabled: deviceId == ''
        },
        {
            to: '/' + game + '/analytics/' + deviceId + '?vendorId=' +vendorId,
            path: 'analytics/:deviceId?/:tab?/:subtab?',
            title: 'Analytics',
            element: <AnalyticsCM/>,
            // disabled: deviceId == ''
        },
        {to: '/' + game + '/charger/' + deviceId + '?vendorId=' +vendorId, path: 'charger/:deviceId?', title: 'Charger', element: <Charger/>},

        {to: '/' + game + '/logs', path: 'logs', title: 'ChargerLogs', element: <AllCmChargerLogs/>},
        //{to:'reports', title:'Reports',component:'<Reports />'},

    ]
    const common_menu = [
        {
            to: '/' + game + '/prefabs/' + deviceId + '?vendorId=' +vendorId,
            path: "prefabs/:deviceId?",
            title: 'Prefabs',
            element: <PrefabsCm game={game}/>
        },
        {
            to: '/' + game + '/archive/' + deviceId + '?vendorId=' +vendorId,
            path: "archive/:deviceId?",
            title: 'Archive',
            element: <ArchiveCM game={game}/>
        },
        {
            to: '/' + game + '/search',
            path: "search",
            title: 'Search',
            element: <AdvancedSearchCM game={game}/>
        },
    ]
    const admin_menu = [
        {
            to: '/' + game + '/chargeManagement',
            path: "chargeManagement",
            title: 'Charge Management',
            element: <ChargeManagement/>
        },
        {
            to: '/' + game + '/whitelistManagement',
            path: "whitelistManagement",
            title: 'White list management',
            element: <WhiteListManagement/>
        },
    ]

    let menu = [
        ...main_menu,
        ...state_menu,
        ...common_menu
    ]


    const routes = (
        <Route>

            {menu.map((object) => (
                <Route path={object.path} element={object.element}/>
            ))}
            {admin_menu.map((object) => (
                <Route path={object.path} element={object.element}/>
            ))}
        </Route>
    );


    return (
        <>
            <div className="wrapper">
                <Drawer
                    sx={{
                        width: 230,
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: {width: 230, boxSizing: 'border-box'},

                    }}
                    variant="permanent"
                >
                    <Toolbar/>

                    <MenuList>
                        {menu.map((object) => (
                            <MenuItem
                                component={NavLink}
                                to={object.to}
                                disabled={object.disabled}
                                className={({isActive, isPending}) =>
                                    isPending ? "pending" : isActive ? "active" : ""
                                }
                            >
                                {object.title}
                            </MenuItem>
                        ))}
                        <hr/>
                        {admin_menu.map((object) => (
                            <MenuItem
                                component={NavLink}
                                to={object.to}
                                disabled={object.disabled}
                                className={({isActive, isPending}) =>
                                    isPending ? "pending" : isActive ? "active" : ""
                                }
                            >
                                {object.title}
                            </MenuItem>
                        ))}
                    </MenuList>
                </Drawer>
                <Box component="main"
                     sx={{flexGrow: 1, p: 3}}
                >
                    <Toolbar/>

                    {['chargeManagement', 'whitelistManagement','search'].indexOf(action) == -1 &&
                        <Box display="flex" alignItems="center" sx={{mb: 2}}>
                            <SearchBarCM />
                        </Box>
                    }

                    <Box mb={2}>
                        <Routes>
                            {routes}
                        </Routes>
                    </Box>
                </Box>
            </div>
        </>

    )
}
