import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import React, {useEffect, useState, useContext} from "react"

import {LoadingButton} from "@mui/lab";
import {useParams} from "react-router-dom";
import DialogTitle from "@mui/material/DialogTitle";
import Editor from "@monaco-editor/react";

import {useCreatePrefabV2BAMutation} from "../../../core/api/bermuda-prefab-api";
import {useCreateStateV2BAMutation} from "../../../core/api/bermuda-state-api";

import {useCreatePrefabV2CMMutation} from "../../../core/api/clockmaker-prefab-api";
import {useCreateStateV2CMMutation} from "../../../core/api/cloclmaker-state-api";

import {useCreatePrefabV2RTMutation} from "../../../core/api/rogerthat-prefab-api";
import {useCreateStateV2RTMutation} from "../../../core/api/rogerthat-state-api";
import {useCreatePrefabV2SCMutation} from "../../../core/api/solitaire-prefab-api";
import {useCreateStateV2SCMutation} from "../../../core/api/solitaire-state-api";

import { parse, stringify } from 'lossless-json'
import {SnackBarContext} from "../../../snackBarProvider";
import {useDispatch} from "react-redux";


const RawDataEdit = (props) => {
    const {deviceId, action, tab: tabParam, game} = useParams()

    const raw = stringify(props.data, null, 2);

    const [rawString, setRawString] = useState()

    useEffect(() => {
        setRawString(raw)
    }, ['raw', raw])

    const [updateState, updateResponse] = useCreateStateV2BAMutation()
    const [updateCMState, updateCMStateResponse] = useCreateStateV2CMMutation();
    const [updateRTState, updateRTStateResponse] = useCreateStateV2RTMutation();
    const [updateSCState, updateSCStateResponse] = useCreateStateV2SCMutation();

    const [createPrefabBA, createPrefabBAResponse] = useCreatePrefabV2BAMutation();
    const [createPrefabCM, createPrefabCMResponse] = useCreatePrefabV2CMMutation();
    const [createPrefabRT, createPrefabRTResponse] = useCreatePrefabV2RTMutation();
    const [createPrefabSC, createPrefabSCResponse] = useCreatePrefabV2SCMutation();

    const useSnackBars = () => useContext(SnackBarContext);
    const {addAlert} = useSnackBars();


    const [isConfirmOpen, setIsConfirmOpen] = useState(false)

    const [prefabForm, updatePrefabForm] = useState({
        name: '',
        isOpened: false,
        env: '',
        version: '1',
        tags: []
    })

    const handleChangeRaw = (value, event) => {
        setRawString(value)
    }


    let version = window.localStorage.getItem('isProd') == 'true' ? 'v2' : 'v2';
    if(game != 'cm') version = 'v2'


    async function handleUpdateState(raw) {
//        console.log(stringify(parse(text)))

        const data = {
            userId: deviceId,
            body: (raw),
            game: game,
            version: version
        }
        let response = false;

        switch (game) {
            case 'ba' :
                response = await updateState(data)
                break;
            case 'cm' :
                response = await updateCMState(data)
                break;
            case 'rt' :
                response = await updateRTState(data)
                break;
            case 'sc' :
                response = await updateSCState(data)
                break;
        }



        if (response.hasOwnProperty('error')) {
            if (response.error.status === 403) {
                addAlert({text: 'This id is not in the whitelist', type: 'error'})
            } else {
                addAlert({text: 'Something went wrong', type: 'error'})
            }
        } else {
            addAlert({text: 'State updated', type: 'success'})
        }

        setIsConfirmOpen(false)
    }

    const dispatch = useDispatch();

    async function createPrefabHandler() {
        const request = {
            data: {
                "name": prefabForm.name,
                "data": rawString,
                "tags": [],
                "version": prefabForm.version,
                "environment": prefabForm.env
            },
            env: prefabForm.env
        };

        let response = false

        switch (game) {
            case 'ba' :
                response = await createPrefabBA(request)
                break;
            case 'cm' :
                response = await createPrefabCM(request)
                break;
            case 'rt' :
                response = await createPrefabRT(request)
                break;
            case 'sc' :
                response = await createPrefabSC(request)
                break;
        }


        updatePrefabForm({
            name: '',
            isOpened: false
        })


        if(response!=undefined) {
            if (response.hasOwnProperty('error')) {
                if (response.error.status === 403) {
                    addAlert({text: 'This id is not in the whitelist', type: 'error'})
                } else {
                    addAlert({text: 'Something went wrong', type: 'error'})
                }
            } else {
                addAlert({text: 'State created', type: 'success'})
            }
        }

    }


    return <>
        <Card>
            <CardHeader
                title={
                    <Typography variant="h6" component="div" display="inline">
                        Update user state
                    </Typography>
                }
                action={
                    <>
                        <Button variant={"outlined"}
                                color={"success"}
                                onClick={
                                    () => {
                                        navigator.clipboard.writeText(rawString)
                                    }
                                }
                        >Copy to clipboard</Button>
                        <Button variant={"contained"}
                                       color={"success"}
                                onClick={() => {
                                    setIsConfirmOpen(true)
                                }}
                        >Save state</Button>
                        <LoadingButton
                            variant={"contained"}
                            color={"warning"}

                            loading={createPrefabBAResponse.isLoading || createPrefabCMResponse.isLoading || createPrefabRTResponse.isLoading || createPrefabSCResponse.isLoading}
                            onClick={() => {
                                updatePrefabForm({...prefabForm, isOpened: true})
                            }}>Create prefab</LoadingButton>
                    </>
                }
            />

            <CardContent>


                <div>

                    <Editor height="90vh"
                            defaultLanguage="json"
                            value={rawString}
                            onChange={handleChangeRaw}
                    />

                </div>
            </CardContent>


        </Card>
        <Dialog open={isConfirmOpen}>
            <DialogContent>
                Save current state for device id <br/>
                <strong>{deviceId}</strong>
            </DialogContent>
            <DialogActions>
                <Button
                    color="secondary"
                    onClick={() => {
                        setIsConfirmOpen(false)
                    }}>
                    Cancel
                </Button>
                <LoadingButton variant={"contained"}
                               color={"warning"}
                               onClick={
                                   () => {
                                       handleUpdateState(rawString)
                                   }
                               }
                               loading={updateResponse.isLoading || updateCMStateResponse.isLoading || updateRTStateResponse.isLoading || updateSCStateResponse.isLoading}
                >Save state</LoadingButton>

            </DialogActions>
        </Dialog>
        <Dialog open={prefabForm.isOpened}>
            <DialogTitle>
                Сохранение префаба

            </DialogTitle>
            <DialogContent>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel id="environment-select-label">Environment</InputLabel>
                            <Select

                                labelId="environment-select-label"
                                onChange={(event) => {
                                    updatePrefabForm({
                                        ...prefabForm,
                                        env: event.target.value
                                    })
                                }}
                                label="Environment"
                            >
                                <MenuItem value="staging">Staging</MenuItem>
                                <MenuItem value="prod">Prod</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item>
                        <TextField
                            label="Название"
                            onChange={(event) => {
                                updatePrefabForm({
                                    ...prefabForm,
                                    name: event.target.value
                                })
                            }}></TextField>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>

                <Button
                    color="secondary"
                    onClick={() => {
                        updatePrefabForm({...prefabForm, isOpened: false})
                    }}>
                    Отмена
                </Button>
                <LoadingButton
                    onClick={createPrefabHandler}
                    variant="outlined"
                    color="warning"
                    loading={createPrefabBAResponse.isLoading || createPrefabCMResponse.isLoading}
                >
                    Создать
                </LoadingButton>

            </DialogActions>
        </Dialog>
    </>

}
export default RawDataEdit

