import api from "./base-api"
import {stringify} from "lossless-json";

const clockmakerStateApiV1 = api.injectEndpoints({
    endpoints: (build) => ({
        getStateV1CM: build.query({
            query: (userId) => `v1/clockmaker/states/${userId}`
        }),
        getStateByFacebookIdV1CM: build.query({
            query: (facebookId) => `v1/clockmaker/states/facebook/${facebookId}`
        }),
        createStateV1CM: build.mutation({
            query: (data) => {

                const {userId, game, ...body} = data

                return {
                    url: `v1/clockmaker/states/${game}/${userId}`,
                    method: "POST",
                    body: body.body
                }
            }
        })
    }),
    overrideExisting: false
})

export const {
    useGetStateV1CMQuery,
    useGetStateByFacebookIdV1CMQuery,
    useCreateStateV1CMMutation

} = clockmakerStateApiV1

const clockmakerStateApiV2 = api.injectEndpoints({
    endpoints: (build) => ({
        getStateV2CM: build.query({
            query: (data = "") => `${data.version}/clockmaker/states/${data.deviceId}/${data.stateId}`,
            providesTags: ['UserStates']
        }),
        getStatesByFacebookIdV2CM: build.query({
            query: (facebookId = "") => `v2/clockmaker/states/facebook/${facebookId}`
        }),
        createStateV2CM: build.mutation({
            query: (data) => {

                const {userId, vendorId, game, ...body} = data

                return {
                    url: `${data.version}/clockmaker/states/${game}/${userId}?vendorId=${vendorId}`,
                    method: "POST",
                    body: body.body
                }
            },
            invalidatesTags: ['UserStates']

        }),
        advancedSearchCM: build.mutation({
            query: (body = {}) => ({
                url: `v2/clockmaker/states/advancedSearch`,
                method: "POST",
                body: body
            }),
        }),
        cmCheckExist: build.mutation({
            query: (body = {}) => ({
                url: `v2/clockmaker/states/exist`,
                method: "POST",
                body: body
            }),
        }),
    }),
    overrideExisting: false
})

export const {
    useGetStateV2CMQuery,
    useGetStatesByFacebookIdV2CMQuery,
    useCreateStateV2CMMutation,
    useAdvancedSearchCMMutation,
    useCmCheckExistMutation
} = clockmakerStateApiV2
