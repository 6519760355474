import {Box, Chip, Tooltip} from "@mui/material";
import {Link} from "react-router-dom";
import {useLocation} from "react-router";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import UsersListWithLinksDialog from "./UsersListWithLinksDialog";
import {useState} from "react";

export default function UserIdsListComponent({data}) {
    const [state, setState] = useState({
        isDialogOpen: false
    })
    const location = useLocation().pathname.replace("/", "");
    const game = location.substring(0, location.indexOf("/"));
    const userIds = data;
    let Content = [];

    const handleOpenList = () => {
        setState({
            ...state,
            isDialogOpen: true
        })
    }
    const handleOnClose = () => {
        setState({
            ...state,
            isDialogOpen: false
        })
    }

    if (userIds.length <= 2) {
        userIds.forEach(item => {
            Content.push(<Link to={`/${game}/${item}/states`}>{item}</Link>)
        })
    } else {
        Content.push(
            <Box className={"users-chip"}>
                <Tooltip title="Show device id list">
                    <Chip icon={<MoreHorizIcon/>}
                          onClick={handleOpenList}
                    />
                </Tooltip>
            </Box>
        )
    }


    return (
        <Box sx={{mt: 1, mb: 1, display: 'flex', flexDirection: "column"}}>
            {Content}
            <UsersListWithLinksDialog
                onClose={handleOnClose}
                data={userIds}
                open={state.isDialogOpen}
                game={game}/>
        </Box>
    )
}

