import React, { useEffect, useState } from 'react';
import { stringify, parse } from "lossless-json";
import Editor from "@monaco-editor/react";
import Loading from "../../components/Common/Loading";
import PrefabsEditor from "./PrefabsEditor";

function PrefabsItem({ selectedItem, setSelectedItem, useQueryHook }) {
    const {
        data,
        isLoading,
        isSuccess,
        isError,
        error,
        refetch,
        isFetching
    } = useQueryHook(selectedItem.id);

    let content = ''



    const handleChangeRaw = (value) => {
        setSelectedItem({
            ...selectedItem,
            data: value
        })
    }


    if (typeof data === "object" && data.hasOwnProperty('raw')) {
        const raw = parse(atob(data.raw))
        content = <PrefabsEditor data={raw} handleChange={handleChangeRaw} />
    }

    useEffect(() => {
        if (typeof data === "object" && data.hasOwnProperty('raw')) {
            setSelectedItem({
                ...selectedItem,
                data: (atob(data.raw))

            })
        }
    },[data])

    return (
        <Loading
            isLoading={isFetching}
            isError={isError}
            error={error}
            isSuccess={isSuccess}
        >
            {content}
        </Loading>
    );
}

export default PrefabsItem;