import api from "./base-api"

const clockmakerPrefabApiV2 = api.injectEndpoints({
    endpoints: (build) => ({
        getPrefabV2CM: build.query({
            query: (id = "") => `v2/clockmaker/prefabs/${id}`,
            providesTags: (result, error, arg) => {
                return result
                    ? [{type: 'PrefabItemCM', id:result.id}, 'PrefabItemCM']
                    : ['PrefabItemCM']
            }
        }),
        getPrefabsListV2CM: build.query({
            query: (id = "") => `v2/clockmaker/prefabs`,
            transformResponse(data) {
                return data.sort((a, b) => (a.date < b.date) ? 1 : ((b.date < a.date) ? -1 : 0))
            },
            providesTags: ['PrefabsCM']
        }),
        getPrefabsInfosV2CM: build.query({
            query: (id = "") => `v2/clockmaker/prefabs/infos`,
            transformResponse(data) {
                return data.sort((a, b) => (a.date < b.date) ? 1 : ((b.date < a.date) ? -1 : 0))
            },
            providesTags: ['PrefabsCM']
        }),
        getPrefabsInfoListV2CM: build.query({
            query: (id = "") => `v2/clockmaker/prefabs/info`,
            transformResponse(data) {
                return data.sort((a, b) => (a.date < b.date) ? 1 : ((b.date < a.date) ? -1 : 0))
            },
            providesTags: ['PrefabsCM']
        }),
        createPrefabV2CM: build.mutation({
            query: (body = {}) => ({
                url: "v2/clockmaker/prefabs",
                method: "POST",
                body: body.data,
                env: body.env
            }),
            invalidatesTags: ['PrefabsCM']
        }),
        deletePrefabV2CM: build.mutation({
            query: (id = "") => ({
                url: `v2/clockmaker/prefabs/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ['PrefabsCM']

        }),
        updatePrefabV2CM: build.mutation({
            query: (data = {}) => ({
                url: `v2/clockmaker/prefabs/${data.id}`,
                method: "PUT",
                body: data.body
            }),
            invalidatesTags: ['PrefabItemCM']

        })
    }),
    overrideExisting: false
})

export const {
    useGetPrefabV2CMQuery,
    useGetPrefabsListV2CMQuery,
    useGetPrefabsInfoListV2CMQuery,
    useGetPrefabsInfosV2CMQuery,
    useCreatePrefabV2CMMutation,
    useDeletePrefabV2CMMutation,
    useUpdatePrefabV2CMMutation
} = clockmakerPrefabApiV2
