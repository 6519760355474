import {Card} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import {formatDate} from "../../../core/utils/constants";


const columns = [
    {field: 'Id', headerName: 'id', hide: true},
    {field: 'groupId', headerName: 'Group ID', width: 100},
    {field: 'groupName', headerName: 'Group name', width: 100},
    {field: 'levelId', headerName: 'Level ID', width: 100},
    {field: 'result', headerName: 'Result', width: 100},
    {field: 'attempts', headerName: 'Attempts', width: 100},
    {field: 'timestamp', headerName: 'Timestamp', width: 100},
    {field: 'version', headerName: 'Version', width: 100}
];

export default function Levels({levelsCM}) {
    const rows = levelsCM ? Object.entries(levelsCM).map((level => ({
        id: level.id.groupId,
        groupId: level.id.groupId,
        groupName: level.id.groupName,
        levelId: level.id.levelId,
        result: level.result.toString(),
        attempts: level.attempts,
        timestamp: formatDate(level.timestamp),
        version: level.version
    }))) : [];

    return (<>
        <Card>
            <DataGrid
                rowHeight={82}
                autoHeight={true}
                rows={rows}
                pagination={true}
                columns={columns}/>
        </Card>
    </>);
}