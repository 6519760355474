import {
    Box,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    TextField
} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import React, {useContext, useState} from "react";
import {useParams} from "react-router-dom";
import PropTypes from 'prop-types';
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Editor from "@monaco-editor/react";
import {SnackBarContext} from "../../snackBarProvider";

import { parse, stringify } from 'lossless-json'
import {useSelector} from "react-redux";

import PrefabsRTItem from "./rt/PrefabsRTItem";
import PrefabsBAItem from "./ba/PrefabsBAItem";
import PrefabsSCItem from "./sc/PrefabsSCItem";
import PrefabsCMItem from "./cm/PrefabsCMItem";


const MyListItem = ({item, selectItem, selectedItem, onDelete}) => {
    const [hover, setHover] = React.useState(false);
    // export default useSnackBars

    const handleMouseEnter = () => {
        setHover(true);
    };

    const handleMouseLeave = () => {
        setHover(false);
    };

    return (
        <ListItem
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <ListItemButton
                onClick={() => selectItem(item)}
                selected={item.id === selectedItem.id}
            >
                <ListItemText
                    primary={item.name}
                    secondary={item.date}
                />
                {hover && (
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={(event) => {
                            event.stopPropagation();
                            onDelete(item)
                        }}
                        size="small"
                        sx={{ml: 1}}
                    >
                        <DeleteIcon/>
                    </IconButton>
                )}
            </ListItemButton>
        </ListItem>
    );
};

export default function Prefabs({
    game,
    prefabsListReady,
    prefabsList,
    updateUserState,
    updateUserStateResponse,
    updatePrefab,
    updatePrefabResponse,
    deletePrefab,
    deletePrefabResponse,
    refetch,
    setSelectedPrefab,
    children
}) {


    const CommonData = useSelector((state) => state.data.CommonData)
    let deviceId = CommonData.deviceId
    let vendorId = CommonData.stateId

    const [selectedItem, setSelectedItem] = useState({});
    const [itemFilter, setItemFilter] = useState("");
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const [state, setState] = useState({
        isConfirmOpen: false
    })
    const useSnackBars = () => useContext(SnackBarContext);
    const {addAlert} = useSnackBars();

    function filterByName(data) {
        return [...data].filter(item => item.name.includes(itemFilter))
    }

    function selectItem(item) {
        setSelectedItem(item)
        if(setSelectedPrefab!=undefined) setSelectedPrefab(item)
    }

    function showConfirmDialog(item) {
        setSelectedItem(item)
        setState({
            ...state,
            isConfirmOpen: true
        })

    }

    async function handleConfirmApplyState(event) {
        setState({
            ...state,
            isConfirmOpen: false
        })

        let version = window.localStorage.getItem('isProd') == 'true' ? 'v2' : 'v2';

        if(game != 'cm') version = 'v2'
        const response = await updateUserState({
            userId: deviceId,
            vendorId: vendorId,
            game: game,
            body: selectedItem.data,
            version: version
        })

        // setOpenSnackbar(response.error)

        if (response.error) {
            if (response.error.status === 403) {
                addAlert({text: 'This id is not in the whitelist', type: 'error'})
            } else {
                addAlert({text: 'Something went wrong', type: 'error'})
            }
        } else {
            addAlert({text: 'State applied', type: 'success'})
        }

    }

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false)
    };


    async function updatePrefabHandler(selectedItem) {
        if(!selectedItem.hasOwnProperty('data') || selectedItem.data==null) return false;


        const response = await updatePrefab({
            id: selectedItem.id,
            body: selectedItem
        });

        if (response.error) {
            if (response.error.status === 403) {
                addAlert({text: JSON.stringify(response.error), type: 'error'})
            } else {
                addAlert({text: 'Something went wrong ' + response.error.status, type: 'error'})
            }
        } else {
            addAlert({text: 'Prefab updated', type: 'success'})
        }
    }

    async function deletePrefabHandler(selectedItem) {

        const response = await deletePrefab(selectedItem.id);
        if (response.error) {
            if (response.error.status === 403) {
                addAlert({text: JSON.stringify(response.error), type: 'error'})
            } else {
                addAlert({text: 'Something went wrong ' + response.error.status, type: 'error'})
            }
        } else {
            addAlert({text: 'Prefab deleted', type: 'success'})
        }

        setSelectedItem({})
    }

    const [isValidJson, setIsValidJson] = useState(true);
    const handleJsonChange = (value, event) => {
        try {
            // Попытка парсить JSON
            const parsedJson = parse(value);
            // Если успешно, обновляем состояние и selectedItem
            setSelectedItem({
                ...selectedItem,
                data: stringify(parsedJson, null, 2)
            });
            setIsValidJson(true);
        } catch (error) {
            // В случае ошибки парсинга, просто сохраняем значение и обновляем состояние
            setSelectedItem({
                ...selectedItem,
                data: value
            });
            setIsValidJson(false);
        }
    }

    return (
        <>
            <Card sx={{mb: 2}}>
                <CardContent>

                    <Grid container>
                        <Grid item sm={3}>
                            <TextField label="Filter by name"
                                       onChange={(e) => setItemFilter(e.target.value)}>
                            </TextField>

                            <List component="nav" className="compact-list">
                                {prefabsListReady && filterByName(prefabsList).map((item) => (
                                    <>
                                        <MyListItem
                                            item={item}
                                            selectItem={selectItem}
                                            selectedItem={selectedItem}
                                            onDelete={deletePrefabHandler}
                                        />
                                        <Divider/>
                                    </>


                                ))}


                            </List>
                        </Grid>
                        <Grid item sm={9}>
                            <div>
                                <Box sx={{mb: 3}}>
                                    <Button variant={"contained"}
                                            disabled={!selectedItem.hasOwnProperty('id')}

                                            sx={{mr: 1}}

                                            onClick={
                                                () => {
                                                    navigator.clipboard.writeText(selectedItem.data)

                                                    addAlert({text: 'JSON Copied', type: 'success'})


                                                }
                                            }
                                    >Copy to clipboard</Button>


                                    <LoadingButton variant={"contained"}
                                                   disabled={!selectedItem.hasOwnProperty('id')}
                                                   loading={updateUserStateResponse.isLoading}
                                                   color={"success"}
                                                   sx={{mr: 1}}
                                                   startIcon={<CheckCircleIcon/>}
                                                   onClick={() => showConfirmDialog(selectedItem)}>Применить</LoadingButton>

                                    <LoadingButton variant={"contained"}
                                                   disabled={!selectedItem.hasOwnProperty('id')}

                                                   loading={updatePrefabResponse.isLoading}
                                                   color={"warning"}
                                                   sx={{mr: 1}}

                                                   onClick={() => updatePrefabHandler(selectedItem)}>Сохранить
                                        изменения</LoadingButton>

                                    <LoadingButton variant={"outlined"}
                                                   disabled={!selectedItem.hasOwnProperty('id')}

                                                   loading={deletePrefabResponse.isLoading}
                                                   color={"error"}
                                                   startIcon={<DeleteIcon/>}
                                                   onClick={() => deletePrefabHandler(selectedItem)}>Удалить</LoadingButton>

                                </Box>
                            </div>
                            {selectedItem.hasOwnProperty("id") &&
                                <div>
                                    <Box sx={{p:1}}><b>Version:</b> {selectedItem.version}, <b>Date:</b> {selectedItem.date}, <b>Env:</b> {selectedItem.environment}, <b>Tags:</b> {selectedItem?.tags?.join(',')}</Box>

                                    {game=='rt' && <PrefabsRTItem selectedItem={selectedItem} setSelectedItem={setSelectedItem} />}
                                    {game=='ba' && <PrefabsBAItem selectedItem={selectedItem} setSelectedItem={setSelectedItem} />}
                                    {game=='sc' && <PrefabsSCItem selectedItem={selectedItem} setSelectedItem={setSelectedItem} />}
                                    {game=='cm' && <PrefabsCMItem selectedItem={selectedItem} setSelectedItem={setSelectedItem} />}
                                </div>
                            }

                        </Grid>
                    </Grid>


                </CardContent>

            </Card>
            <Dialog open={state.isConfirmOpen}>
                <DialogContent>
                    Применить стэйт <br/>
                    <strong>{selectedItem.name}</strong>
                    <br/> для state id:<br/>
                    <strong>{deviceId}</strong>
                    <br/> и vendor Id:<br/>
                    <strong>{vendorId}</strong>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="secondary"
                        onClick={() => {
                            setState({...state, isConfirmOpen: false})
                        }}>
                        Отменить
                    </Button>
                    <LoadingButton
                        onClick={handleConfirmApplyState}
                        variant="outlined"
                        color="warning"
                    >
                        Да
                    </LoadingButton>

                </DialogActions>
            </Dialog>
        </>
    );
}

Prefabs.propTypes = {
    prefabsListReady: PropTypes.bool.isRequired,
    prefabsList: PropTypes.array.isRequired,
    updateUserState: PropTypes.func.isRequired,
    updateUserStateResponse: PropTypes.object.isRequired,
    updatePrefab: PropTypes.func.isRequired,
    updatePrefabResponse: PropTypes.object.isRequired,
    deletePrefab: PropTypes.func.isRequired,
    deletePrefabResponse: PropTypes.object.isRequired,
    refetch: PropTypes.func.isRequired

};
