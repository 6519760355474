import {Backdrop, Box, CircularProgress} from "@mui/material";

const Loading = (props) => {
    const getErrorText = () => {
        if (!props.hasOwnProperty("error")) return "network error";
        if (props.error.hasOwnProperty('data')) {
            let error_text = '';
            if (props.error.data == null) return props.error.status + ' ' + error_text
            error_text = (typeof props.error.data == "object") ? props.error.data.error : "network error "
            return props.error.status + ' ' + error_text
        }
        if (props.error.hasOwnProperty('error')) return props.error.error
        return "undefined error";
    }

    return (
        <>
            {props.isLoading ? <Box sx={{display: 'flex'}}>
                    <CircularProgress/>
                </Box> :
                <>
                    {props.isError ? getErrorText() : <div>{props.children}</div>}
                </>
            }

            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={props.isFetching}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
        </>

    )
}

export default Loading