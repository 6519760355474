import React, {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {Box, Tab, Tabs,} from "@mui/material";
import {TabContext, TabPanel} from "@mui/lab";
import Loading from "../../components/Common/Loading";
import PaymentsCM from "../Payments/cm";
import ConsumablesCM from "../Consumables/cm";
import {stringify, parse} from "lossless-json";
import {getDeviceIdAbsentWarning} from "../../core/utils/constants";
import WarningTwoToneIcon from "@mui/icons-material/WarningTwoTone";
import InAppCM from "../Payments/InAppCM";
import TechInfoCM from "../TechInfo/techInfoCM";


function AnalyticsCM(props) {
    const {action, tab: tabParam, subtab: subTabParam, game} = useParams()
    const CommonData = useSelector((state) => state.data.CommonData)
    const deviceId = CommonData.deviceId
    const stateId = CommonData.stateId

    const link_prefix = '/' + game + '/' + action + '/' + deviceId + '/'
    const link_postfix = '?vendorId='+stateId

    const tab = tabParam || 'balance_items'
    const subTab = subTabParam || 'houses';
    const ingameSubTab = subTabParam || 'main';
    const dispatch = useDispatch();
    const deviceIdIsEmpty = deviceId == null || deviceId == '' || typeof deviceId == 'object';

    const version = window.localStorage.getItem('isProd') == 'true' ? 'v2' : 'v2';



    const [tabIndex, setTabIndex] = useState(tab);
    const [subTabIndex, setSubTabIndex] = useState(subTab);
    const [ingameSubTabIndex, setIngameSubTabIndex] = useState(ingameSubTab);

    const handleTabChange = (event, newTabIndex) => {
        setTabIndex(newTabIndex);
    };

    const handleSubTabChange = (event, newTabIndex) => {
        setSubTabIndex(newTabIndex);
    };

    const handleIngameSubTabChange = (event, newTabIndex) => {
        setIngameSubTabIndex(newTabIndex);
    };

    useEffect(() => {

    }, [deviceId, dispatch]);

    let status = ""

    let content = ""
    let panel1 = ""
    let panel2 = ""

    let charges = ""

    if (deviceIdIsEmpty) {
        return (
            <>
                <div>{getDeviceIdAbsentWarning()}</div>
            </>
        );
    }


    if(!deviceIdIsEmpty) {
        content = <TabContext value={tabIndex}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs value={tabIndex} onChange={handleTabChange} aria-label="basic tabs example">
                    <Tab label="Balance items" value="balance_items" component={Link}
                         to={link_prefix + "balance_items" + link_postfix}/>
                    <Tab label="Payments" value="payments" component={Link} to={link_prefix + "payments" + link_postfix}/>
                    <Tab label="Inapp" value="inapp" component={Link} to={link_prefix + "inapp" + link_postfix}/>
                    <Tab label="Device" value="device" component={Link} to={link_prefix + "device" + link_postfix}/>

                </Tabs>
            </Box>

            <TabPanel value="balance_items">
                <ConsumablesCM deviceId={deviceId}/>
            </TabPanel>
            <TabPanel value="payments">
                <PaymentsCM deviceId={deviceId}/>
            </TabPanel>
            <TabPanel value="inapp">
                <InAppCM deviceId={deviceId}/>
            </TabPanel>
            <TabPanel value="device">
                <TechInfoCM isSimple={true} deviceId={deviceId}/>
            </TabPanel>
        </TabContext>
    }


    return (
        <>
            <div>{content}</div>
        </>
    );

}

export default AnalyticsCM
