import React, {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {Box, Tab, Tabs,} from "@mui/material";
import {TabContext, TabPanel} from "@mui/lab";
import PfLocationsTable from "./ba/PfLocationsTable";
import RawDataEdit from "./ba/RawDataEdit";
import Loading from "../../components/Common/Loading";
import {useGetStateV2CMQuery} from "../../core/api/cloclmaker-state-api";
import Houses from "./cm/Houses";
import Events from "./cm/Events";
import Puzzles from "./cm/Puzzles";
import Collections from "./cm/Collections";
import Mates from "./cm/Mates";
import Levels from "./cm/Levels";
import Main from "./cm/Main";
import Materials from "./cm/Materials";
import Tricks from "./cm/Tricks";
import CollectionItems from "./cm/CollectionItems";
import Bonuses from "./cm/Bonuses";
import PaymentsCM from "../Payments/cm";
import ConsumablesCM from "../Consumables/cm";
import {stringify, parse} from "lossless-json";
import {getDeviceIdAbsentWarning} from "../../core/utils/constants";
import WarningTwoToneIcon from "@mui/icons-material/WarningTwoTone";
import InAppCM from "../Payments/InAppCM";


function UserStatesCM(props) {
    const {action, tab: tabParam, subtab: subTabParam, game} = useParams()
    const CommonData = useSelector((state) => state.data.CommonData)
    const deviceId = CommonData.deviceId
    const stateId = CommonData.stateId

    const link_prefix = '/' + game + '/' + action + '/' + deviceId + '/'
    const link_postfix = '?vendorId='+stateId

    const tab = tabParam || 'update'
    const subTab = subTabParam || 'houses';
    const ingameSubTab = subTabParam || 'main';
    const dispatch = useDispatch();
    const deviceIdIsEmpty = deviceId == null || deviceId == '' || typeof deviceId == 'object';

    const version = window.localStorage.getItem('isProd') == 'true' ? 'v2' : 'v2';


    const {
        data: obj,
        isLoading,
        isFetching,
        isSuccess,
        isError,
        error
    } = useGetStateV2CMQuery({deviceId: deviceId, stateId: stateId, version: version})


    const [tabIndex, setTabIndex] = useState(tab);
    const [subTabIndex, setSubTabIndex] = useState(subTab);
    const [ingameSubTabIndex, setIngameSubTabIndex] = useState(ingameSubTab);

    const handleTabChange = (event, newTabIndex) => {
        setTabIndex(newTabIndex);
    };

    const handleSubTabChange = (event, newTabIndex) => {
        setSubTabIndex(newTabIndex);
    };

    const handleIngameSubTabChange = (event, newTabIndex) => {
        setIngameSubTabIndex(newTabIndex);
    };

    useEffect(() => {

    }, [deviceId, dispatch]);

    let status = ""

    let content = ""
    let panel1 = ""
    let panel2 = ""

    let charges = ""

    if (deviceIdIsEmpty) {
        return (
            <>
                <div>{getDeviceIdAbsentWarning()}</div>
            </>
        );
    }

    if (typeof obj === "object" && obj.hasOwnProperty('data')) {

        if (typeof obj.data === "object" && obj.data.hasOwnProperty('error')) {
            content = obj.data.error
        } else if (typeof obj.data == "object") {
            const raw = parse(atob(obj.value))
            const data = JSON.parse(atob(obj.value))


            if (data.hasOwnProperty('progress'))
                panel2 = <PfLocationsTable locations={data.progress.locations}/>
            content = <TabContext value={tabIndex}>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <Tabs value={tabIndex} onChange={handleTabChange} aria-label="basic tabs example">
                        <Tab label="Update" value="update" component={Link} to={link_prefix + "update" + link_postfix}/>
                        {/*<Tab label="Clans"  value="clans" component={Link} to={link_prefix+"clans"}  />*/}
                        <Tab label="Progress" value="progress" component={Link} to={link_prefix + "progress" + link_postfix}/>
                        <Tab label="Ingame" value="ingame" component={Link} to={link_prefix + "ingame" + link_postfix}/>
                        {/*<Tab label="Balance items" value="balance_items" component={Link}*/}
                        {/*     to={link_prefix + "balance_items"}/>*/}
                        {/*<Tab label="Payments" value="payments" component={Link} to={link_prefix + "payments"}/>*/}
                        {/*<Tab label="Inapp" value="inapp" component={Link} to={link_prefix + "inapp"}/>*/}

                    </Tabs>
                </Box>

                <TabPanel value="progress">
                    <TabContext value={subTabIndex}>
                        <Box className={"subtabs"}>
                            <Tabs value={subTabIndex} onChange={handleSubTabChange} aria-label="basic tabs example">
                                <Tab label="Houses" value="houses" component={Link} to={link_prefix + "progress/houses" + link_postfix}/>
                                <Tab label="Events" value="events" component={Link} to={link_prefix + "progress/events" + link_postfix}/>
                                <Tab label="Puzzles" value="puzzles" component={Link}
                                     to={link_prefix + "progress/puzzles" + link_postfix}/>
                                <Tab label="Collections" value="collections" component={Link}
                                     to={link_prefix + "progress/collections" + link_postfix}/>
                                <Tab label="Mates" value="mates" component={Link} to={link_prefix + "progress/mates" + link_postfix}/>
                                <Tab label="Levels" value="levels" component={Link} to={link_prefix + "progress/levels" + link_postfix}/>
                            </Tabs>
                            <TabPanel value="houses">
                                <Houses housesData={data.Buildings.States}/>
                            </TabPanel>
                            <TabPanel value="events">
                                <Events eventsData={data.OffersState._offers}/>
                            </TabPanel>
                            <TabPanel value="puzzles">
                                <Puzzles puzzles={data.PuzzlesState}
                                         items={data.Items.States}
                                />
                            </TabPanel>
                            <TabPanel value="collections">
                                {data.hasOwnProperty('CollectionsState') &&
                                    <Collections collections={data.CollectionsState.States}/>}
                            </TabPanel>
                            <TabPanel value="mates">
                                <Mates mates={data.Items.States}/>
                            </TabPanel>
                            <TabPanel value="levels">
                                <Levels mates={data.levelsCM}/>
                            </TabPanel>
                        </Box>
                    </TabContext>
                </TabPanel>

                <TabPanel value="ingame">
                    <TabContext value={ingameSubTabIndex}>
                        <Box className={"subtabs"}>
                            <Tabs value={ingameSubTabIndex} onChange={handleIngameSubTabChange}
                                  aria-label="basic tabs example">
                                <Tab label="Main" value="main" component={Link} to={link_prefix + "ingame/main"}/>
                                <Tab label="Materials" value="materials" component={Link}
                                     to={link_prefix + "ingame/materials"}/>
                                <Tab label="Tricks" value="tricks" component={Link} to={link_prefix + "ingame/tricks"}/>
                                <Tab label="Bonuses" value="bonuses" component={Link} to={link_prefix + "ingame/bonuses"}/>
                                <Tab label="Collection items" value="collectionItems" component={Link}
                                     to={link_prefix + "ingame/collectionItems"}/>
                            </Tabs>
                            <TabPanel value="main">
                                <Main items={data.Items.States}
                                      extraLives={data.LivesState._extraLives}
                                      unlimitedLive={data.LivesState._infinityLivesTimeEnd}
                                />
                            </TabPanel>
                            <TabPanel value="materials">
                                <Materials materials={data.Items.States}/>
                            </TabPanel>
                            <TabPanel value="tricks">
                                <Tricks tricks={data.Items.States}/>
                            </TabPanel>
                            <TabPanel value="bonuses">
                                <Bonuses bonuses={data.Items.States}/>
                            </TabPanel>
                            <TabPanel value="collectionItems">
                                <CollectionItems items={data.Items.States}/>
                            </TabPanel>
                        </Box>
                    </TabContext>
                </TabPanel>

                {/*<TabPanel value="clans" >*/}

                {/*    <Clans data={data.ClansState}/>*/}
                {/*</TabPanel>*/}
                <TabPanel value="update">
                    <RawDataEdit data={raw}></RawDataEdit>
                </TabPanel>
                <TabPanel value="balance_items">
                    <ConsumablesCM deviceId={deviceId}/>
                </TabPanel>
                <TabPanel value="payments">
                    <PaymentsCM deviceId={deviceId}/>
                </TabPanel>
                <TabPanel value="inapp">
                    <InAppCM deviceId={deviceId}/>
                </TabPanel>
            </TabContext>
        } else {
            content = obj.data
        }



    } else {
        content = obj?.error
    }

    return (
        <>
            <Loading isError={isError} isLoading={isLoading||isFetching} error={error}>
                <div>{status}</div>
                <div>{content}</div>
            </Loading>


        </>
    );

}

export default UserStatesCM
